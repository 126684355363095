import React, {useEffect, useState} from 'react';

import Layout from "../components/layout";

function LegalNotice({}) {

    return (
        <Layout>
            <div className="pt-24 pb-1">
                <div className="mx-auto">
                    <div className="w-full mx-auto">
                        <div>
                            <div className='py-14 text-center text-3xl'>
                                <h1>Conditions Générales de Vente</h1>
                            </div>
                            <br/>
                            <div className='bg-white text-gray-600'>
                                <div className='container w-full mx-auto py-10'>
                                    <p align="center">
                                        <em>
                                            Les présentes Conditions Générales de Vente sont à jour au 17 août 2021
                                        </em>
                                    </p>
                                    <p>
                                        <strong>1. </strong>
                                        <strong>DÉFINITIONS</strong>
                                    </p>
                                    <p>
                                        <strong><u> </u></strong>
                                    </p>
                                    <p>
                                        1.1. Les présentes Conditions Générales de Vente (ci-après ‘’ CGV ‘’) sont
                                        proposées par la Micro-entreprise Radouane El Moukhtari (ci-après ‘’ La
                                        Micro-entreprise ’’), micro , inscrite au Registre du commerce et des
                                        sociétés de Bordeaux sous le numéro 830334199, , dont le siège social est
                                        fixé au 108 Cours Saint-Louis, 33300 Bordeaux, France.
                                    </p>
                                    <p>
                                        1.2. Son numéro de téléphone non surtaxé est le 07 60 96 39 12, et son
                                        adresse électronique est dev.eMenila@gmail.com.
                                    </p>
                                    <p>
                                        1.3. La Micro-entreprise est propriétaire et éditeur du site internet
                                        https://eMenila.fr/ (ci-après ‘’ le Site ‘’). Le Site est hébergé par
                                        Netlify, N/A, domiciliée au 2343 3rd Street, 94107 San Francisco,
                                        États-Unis, joignable au +1 415 691 1573. Le directeur de la publication
                                        est Raidouane EL MOUKHTARI.
                                    </p>
                                    <p>
                                        1.4. Le Site propose au Client (ci-après ‘’ le Client ‘’) la possibilité de
                                        Le site permet aux clients de pouvoir enregistrer grâce à notre plugin leur
                                        produits favoris depuis leur site marchand, de les retrouver sur notre site
                                        &gt; espace perso &gt; mes produits, mais aussi de pouvoir créer des
                                        projets, des listes shopping et de pouvoir supprimer le fond des images de
                                        leur produits. (ci-après les ‘’services‘’).
                                    </p>
                                    <p>
                                        1.5. Avant toute utilisation du Site, le Client doit s’assurer qu’il
                                        dispose des moyens techniques et informatiques lui permettant d’utiliser le
                                        Site et de commander les services sur le Site, et que son navigateur permet
                                        un accès sécurisé au Site. Le Client doit également s’assurer que la
                                        configuration informatique de son matériel/équipement est en bon état et ne
                                        contient pas de virus.
                                    </p>
                                    <p>
                                        <strong>2. </strong>
                                        <strong>APPLICATION ET OPPOSABILITÉ DES CGV</strong>
                                    </p>
                                    <p>
                                        2.1. Les présentes CGV ont pour objet de définir l’ensemble des conditions
                                        dans lesquelles la Micro-entreprise commercialise les Services tels que
                                        proposés à la vente sur le Site aux Clients. Elles s’appliquent donc à
                                        toute Commande (ci-après ‘’ Commande ‘’) de Services passée sur le Site par
                                        le Client.
                                    </p>
                                    <p>
                                        2.2. Le Client déclare avoir pris connaissance et accepté les présentes CGV
                                        avant la passation de sa Commande.
                                    </p>
                                    <p>
                                        2.3. La validation de la Commande vaut donc acceptation de ces CGV.
                                        Celles-ci sont régulièrement mises à jour, les CGV applicables sont celles
                                        en vigueur sur le Site à la date de passation de la Commande.
                                    </p>
                                    <p>
                                        2.4. Toute condition contraire posée par le Client serait donc, à défaut
                                        d’acceptation expresse, inopposable à la Micro-entreprise quel que soit le
                                        moment où elle aura pu être portée à sa connaissance.
                                    </p>
                                    <p>
                                        2.5. Le fait que la Micro-entreprise ne se prévale pas à un moment donné
                                        d’une quelconque disposition des présentes CGV ne peut être interprété
                                        comme valant renonciation à se prévaloir ultérieurement d’une quelconque
                                        disposition desdites CGV.
                                    </p>
                                    <p>
                                        <strong>3. </strong>
                                        <strong>COMMANDE DE SERVICES SUR LE SITE</strong>
                                    </p>
                                    <p>
                                        La Micro-entreprise se réserve le droit de corriger le contenu du Site à
                                        tout moment.
                                    </p>
                                    <p>
                                        3.1. Le Client sélectionne le ou les Services qu’il souhaite acheter, et
                                        peut accéder à tout moment au récapitulatif de sa Commande.
                                    </p>
                                    <p>
                                        3.2. Le récapitulatif de la Commande présente la liste du ou des Services
                                        que le Client a sélectionné(s), et comprend les éventuels frais annexes
                                        tels que le prix de livraison venant s’ajouter au prix du ou des Services
                                        de la Commande. Le Client a la possibilité de modifier sa Commande et de
                                        corriger d’éventuelles erreurs avant de procéder à l’acceptation de sa
                                        Commande.
                                    </p>
                                    <p>
                                        3.3. Après avoir accédé au récapitulatif de sa Commande, le Client confirme
                                        l’acceptation de sa Commande en cochant la case de validation des CGV, puis
                                        en cliquant sur l’icône de validation de la Commande. La mention ‘’Commande
                                        avec obligation de paiement‘’ ou une formule analogue dénuée de toute
                                        ambiguïté figure à côté de l’icône de validation de la Commande afin de
                                        s’assurer que le Client reconnaît explicitement son obligation de paiement
                                        de la Commande.
                                    </p>
                                    <p>
                                        3.4. Après acceptation des CGV et validation de la Commande avec obligation
                                        de paiement, le contrat est valablement conclu entre la Micro-entreprise et
                                        le Client et les engage de manière irrévocable.
                                    </p>
                                    <p>
                                        <a name="_Ref423812884">
                                            3.5. Après la validation de sa Commande et afin de pouvoir procéder au
                                            paiement, le Client saisit les coordonnées auxquelles il souhaite
                                            obtenir réception du ou des service(s), et de facturation si elles sont
                                            différentes. Le processus de réception du ou des service(s) est décrit
                                            à l’article 5 des présentes CGV.
                                        </a>
                                    </p>
                                    <p>
                                        <a name="_Ref423812917">
                                            3.6. La Micro-entreprise lui envoie alors une confirmation de Commande
                                            par email, reprenant les éléments du récapitulatif de sa Commande et
                                            les adresses de livraison et le cas échéant de facturation renseignées.
                                        </a>
                                    </p>
                                    <p>
                                        3.7. Après avoir validé ses coordonnées de livraison et le cas échéant de
                                        facturation, le Client procède au paiement de sa Commande selon les
                                        modalités précisées ci-après.
                                    </p>
                                    <p>
                                        <strong>4. </strong>
                                        <strong>PRIX ET CONDITIONS DE PAIEMENT DE LA COMMANDE</strong>
                                    </p>
                                    <p>
                                        4.1. Les prix sont mentionnés sur le Site dans les descriptifs des
                                        Services, en euros et hors taxe et toutes taxes comprises.
                                    </p>
                                    <p>
                                        4.2. Le montant total est indiqué dans le récapitulatif de la Commande,
                                        avant que le Client n’accepte les présentes CGV, valide sa Commande,
                                        renseigne et valide ses coordonnées de livraison et le cas échéant de
                                        facturation et procède au paiement. Ce montant total est indiqué toutes
                                        taxes comprises.
                                    </p>
                                    <p>
                                        4.3. La Commande des Services sur le Site est payable en euros. La totalité
                                        du paiement doit être réalisée au jour de la Commande par le Client, par
                                        carte bancaire, sauf conditions particulières de vente acceptées
                                        expressément par le Client et la Micro-entreprise.
                                    </p>
                                    <p>
                                        4.4. En cas de paiement par carte bancaire, le Site utilise le système de
                                        sécurisation de stripe, prestataire spécialisé dans la sécurisation de
                                        paiement en ligne. Ce système garantit au Client la totale confidentialité
                                        de ses informations bancaires. La transaction bancaire par carte bancaire,
                                        réalisée entre le Client et le système sécurisé est donc entièrement
                                        cryptée et protégée. Les coordonnées bancaires du Client ne sont pas
                                        stockées informatiquement par la Micro-entreprise. Les conditions générales
                                        d’utilisation de stripe sont consultable à l’adresse suvante :
                                        https://stripe.com/fr/privacy.
                                    </p>
                                    <p>
                                        4.5. Le Client garantit à la Micro-entreprise qu’il dispose des
                                        autorisations nécessaires pour utiliser le mode de paiement, lors de la
                                        passation de la Commande.
                                    </p>
                                    <p>
                                        4.6. La Micro-entreprise se réserve le droit de suspendre ou d’annuler
                                        toute exécution et/ou livraison d’une Commande, quelle que soit sa nature
                                        et son niveau d’exécution, en cas de défaut de paiement ou de paiement
                                        partiel de toute somme qui serait due par le Client à la Micro-entreprise,
                                        en cas d’incident de paiement, ou en cas de fraude ou tentative de fraude
                                        relative à l’utilisation du site et au paiement d’une Commande.
                                    </p>
                                    <p>
                                        <a name="_Toc237668583"></a>
                                        <a name="_Ref423812088">
                                            <strong>5. </strong>
                                            <strong> UTILISATION DES SERVICES</strong>
                                        </a>
                                        <strong></strong>
                                    </p>
                                    <p>
                                        <u> </u>
                                    </p>
                                    <p>
                                        5.1. <strong> </strong> Le ou les service(s) est (sont) automatiquement
                                        délivré(s) au Client à compter de la validation de sa Commande.    <strong> </strong>
                                    </p>
                                    <p>
                                        5.2. <strong> </strong>Le Client doit s’assurer que les informations
                                        communiquées lors de la passation de sa Commande sont correctes.    <strong> </strong>
                                    </p>
                                    <p>
                                        5.3. La Micro-entreprise ne sera pas non plus responsable si la non
                                        réception des Services est due au fait d’un tiers en dehors de son
                                        intervention ou en cas de vol. <strong> </strong>
                                    </p>
                                    <p>
                                        <strong>6. </strong>
                                        <strong>SERVICE CLIENT</strong>
                                    </p>
                                    <p>
                                        6.1. Pour toute demande d’informations, de précisions ou pour toute
                                        réclamation, le Client doit contacter, en priorité, le service client de la
                                        Micro-entreprise, afin de permettre à ce dernier de tenter de trouver une
                                        solution au problème.
                                    </p>
                                    <p>
                                        6.2. Le service client de la Micro-entreprise est accessible de 14h-18h du
                                        lundi au samedi en utilisant les coordonnées suivantes :
                                    </p>
                                    <p>
                                        - Téléphone : 07 60 96 39 12
                                    </p>
                                    <p>
                                        - email : dev.eMenila@gmail.com
                                    </p>
                                    <p>
                                        - courrier : 108 Cours Saint-Louis, 33300 Bordeaux, France
                                    </p>
                                    <p>
                                        <strong> </strong>
                                    </p>
                                    <p>
                                        <u> </u>
                                    </p>
                                    <p>
                                        <strong>7. </strong>
                                        <strong>OBLIGATIONS DU CLIENT</strong>
                                    </p>
                                    <p>
                                        <strong><u> </u></strong>
                                    </p>
                                    <p>
                                        7.1. Le Client s’engage à respecter les termes des présentes CGV.
                                    </p>
                                    <p>
                                        7.2. Le Client s’engage à utiliser le Site et les services de manière
                                        conforme aux instructions de la Micro-entreprise.
                                    </p>
                                    <p>
                                        7.3. Le Client convient qu’il n’utilise le Site que pour son usage
                                        personnel, conformément aux présentes CGV. A cet égard, le Client convient
                                        de s’abstenir :
                                    </p>
                                    <p>
                                        - D’utiliser le Site de toute manière illégale, pour toute finalité
                                        illégale ou de toute manière incompatible avec ces CGV.
                                    </p>
                                    <p>
                                        - De vendre, copier, reproduire, louer, prêter, distribuer, transférer ou
                                        concéder sous sous-licence tout ou partie des contenus figurant sur le Site
                                        ou de décompiler, désosser, désassembler, modifier, afficher sous forme
                                        lisible par le Client, tenter de découvrir tout code source ou utiliser
                                        tout logiciel activant ou comprenant tout ou partie du Site.
                                    </p>
                                    <p>
                                        - De tenter d’obtenir l’accès non autorisé au système informatique du Site
                                        ou de se livrer à toute activité perturbant, diminuant la qualité ou
                                        interférant avec les performances ou détériorant les fonctionnalités du
                                        Site.
                                    </p>
                                    <p>
                                        - D’utiliser le Site à des fins abusives en y introduisant volontairement
                                        des virus ou tout autre programme malveillant et de tenter d’accéder de
                                        manière non autorisée au Site.
                                    </p>
                                    <p>
                                        - De porter atteinte aux droits de propriété intellectuelle de la
                                        Micro-entreprise
                                    </p>
                                    <p>
                                        - De dénigrer le Site
                                    </p>
                                    <p>
                                        7.4. Si, pour un quelconque motif, la Micro-entreprise considère que le
                                        Client ne respecte pas les présentes CGV, la Micro-entreprise peut à tout
                                        moment, et à son entière discrétion, supprimer son accès au Site et prendre
                                        toutes mesures incluant toute action judiciaire civile et pénale à son
                                        encontre.
                                    </p>
                                    <p>
                                        <strong>8. </strong>
                                        <strong>DROIT DE RÉTRACTATION</strong>
                                    </p>
                                    <p>
                                        <strong> </strong>
                                    </p>
                                    <p>
                                        8.1. Conformément aux articles L.221-18 et suivants du Code de la
                                        consommation, le Client dispose d’un délai de 14 jours à compter de la
                                        validation de sa Commande sur le Site pour exercer son droit de
                                        rétractation auprès de la Micro-entreprise, sans avoir à justifier de
                                        motifs ni à payer de pénalité.
                                    </p>
                                    <p>
                                        8.2. Tous les Produits/Services peuvent faire l’objet d’une rétractation,
                                        excepté ceux exclus par l’article L. 221-28 du Code de la consommation,
                                        reproduit ci-dessous :
                                    </p>
                                    <div>
                                        <p>
                                            <em>
                                                Le droit de rétractation ne peut être exercé pour les contrats :
                                            </em>
                                        </p>
                                        <p>
                                            <em>
                                                1° De fourniture de services pleinement exécutés avant la fin du
                                                délai de rétractation et dont l'exécution a commencé après accord
                                                préalable exprès du consommateur et renoncement exprès à son droit
                                                de rétractation ;
                                            </em>
                                        </p>
                                        <p>
                                            <em>
                                                2° De fourniture de biens ou de services dont le prix dépend de
                                                fluctuations sur le marché financier échappant au contrôle du
                                                professionnel et susceptibles de se produire pendant le délai de
                                                rétractation ;
                                            </em>
                                        </p>
                                        <p>
                                            <em>
                                                3° De fourniture de biens confectionnés selon les spécifications du
                                                consommateur ou nettement personnalisés ;
                                            </em>
                                        </p>
                                        <p>
                                            <em>
                                                4° De fourniture de biens susceptibles de se détériorer ou de se
                                                périmer rapidement ;
                                            </em>
                                        </p>
                                        <p>
                                            <em>
                                                5° De fourniture de biens qui ont été descellés par le consommateur
                                                après la livraison et qui ne peuvent être renvoyés pour des raisons
                                                d'hygiène ou de protection de la santé ;
                                            </em>
                                        </p>
                                        <p>
                                            <em>
                                                6° De fourniture de biens qui, après avoir été livrés et de par
                                                leur nature, sont mélangés de manière indissociable avec d'autres
                                                articles ;
                                            </em>
                                        </p>
                                        <p>
                                            <em>
                                                7° De fourniture de boissons alcoolisées dont la livraison est
                                                différée au-delà de trente jours et dont la valeur convenue à la
                                                conclusion du contrat dépend de fluctuations sur le marché
                                                échappant au contrôle du professionnel ;
                                            </em>
                                        </p>
                                        <p>
                                            <em>
                                                8° De travaux d'entretien ou de réparation à réaliser en urgence au
                                                domicile du consommateur et expressément sollicités par lui, dans
                                                la limite des pièces de rechange et travaux strictement nécessaires
                                                pour répondre à l'urgence ;
                                            </em>
                                        </p>
                                        <p>
                                            <em>
                                                9° De fourniture d'enregistrements audio ou vidéo ou de logiciels
                                                informatiques lorsqu'ils ont été descellés par le consommateur
                                                après la livraison ;
                                            </em>
                                        </p>
                                        <p>
                                            <em>
                                                10° De fourniture d'un journal, d'un périodique ou d'un magazine,
                                                sauf pour les contrats d'abonnement à ces publications ;
                                            </em>
                                        </p>
                                        <p>
                                            <em>11° Conclus lors d'une enchère publique ;</em>
                                        </p>
                                        <p>
                                            <em>
                                                12° De prestations de services d'hébergement, autres que
                                                d'hébergement résidentiel, de services de transport de biens, de
                                                locations de voitures, de restauration ou d'activités de loisirs
                                                qui doivent être fournis à une date ou à une période déterminée ;
                                            </em>
                                        </p>
                                        <p>
                                            <em>
                                                13° De fourniture d'un contenu numérique non fourni sur un support
                                                matériel dont l'exécution a commencé après accord préalable exprès
                                                du consommateur et renoncement exprès à son droit de rétractation.
                                            </em>
                                        </p>
                                    </div>
                                    <p>
                                        8.3. Pour exercer son droit de rétractation de la Commande le Client doit
                                        notifier sa décision de rétractation au moyen du formulaire de rétractation
                                        proposé en annexe des présentes ou au moyen d’une déclaration dénuée
                                        d’ambiguïté, sans justifier de motifs. Le Client peut communiquer sa
                                        décision de rétractation à la Micro-entreprise par tout moyen, notamment en
                                        l’envoyant par courrier à la Micro-entreprise à l’adresse suivante :    <strong>108 Cours Saint-Louis, 33300 Bordeaux, France</strong> ou par
                                        courriel à <strong>dev.eMenila@gmail.com</strong>.
                                    </p>
                                    <p>
                                        8.4. En cas de notification à la Micro-entreprise par le Client de sa
                                        décision de rétractation, quel que soit le moyen employé, la
                                        Micro-entreprise lui enverra sans délai un accusé de réception de la
                                        rétractation sur un support durable (notamment par courriel).
                                    </p>
                                    <p>
                                        8.5. En cas de rétractation du Client, le remboursement du ou des Services
                                        qui a ou ont fait l’objet du droit de rétractation est effectué par la
                                        Micro-entreprise par le même moyen de paiement que celui utilisé pour la
                                        transaction initiale, sauf si le Client convient expressément d’un moyen
                                        différent. En tout état de cause, ce remboursement n’occasionnera pas de
                                        frais pour le Client. Le remboursement est opéré dans les meilleurs délais,
                                        et au plus tard 14 jours à compter du jour où la Micro-entreprise est
                                        informée de la décision du Client de rétractation de sa Commande. En cas
                                        d’utilisation des Services dans le délai de rétractation, le Client est
                                        considéré comme avoir expressément renoncé à son droit de rétractation.
                                    </p>
                                    <p>
                                        <strong>9. </strong>
                                        <strong>RESPONSABILIT</strong>
                                        <strong>É</strong>
                                        <strong></strong>
                                    </p>
                                    <p>
                                        <strong><u> </u></strong>
                                    </p>
                                    <p>
                                        9.1. La Micro-entreprise met en œuvre toutes les mesures propres à assurer
                                        au Client la fourniture, dans des conditions optimales, d’un ou de
                                        service(s) de qualité. Elle ne saurait cependant en aucun cas voir sa
                                        responsabilité engagée pour toute inexécution ou mauvaise exécution de tout
                                        ou partie des prestations prévues au contrat, qui serait imputable soit au
                                        Client, soit au fait imprévisible et insurmontable d’un tiers étranger au
                                        contrat, soit à un cas de force majeure. Plus généralement, si la
                                        responsabilité de la Micro-entreprise se trouvait engagée, elle ne pourrait
                                        en aucun cas accepter d’indemniser le Client pour des dommages indirects ou
                                        dont l’existence et/ou le quantum ne seraient pas établi par des preuves.
                                    </p>
                                    <p>
                                        9.2. La Micro-entreprise ne saura être tenu responsable des dommages causés
                                        par un mésusage d’un de ses services ou par non-respect des précautions
                                        d’utilisation et conditions d’hygiène, de stockage et de sécurité lors de
                                        l’utilisation d’un de ses Produits/Services.
                                    </p>
                                    <p>
                                        9.3. Le Site peut contenir des liens vers d’autres sites non édités ni
                                        contrôlés par la Micro-entreprise, qui ne pourra être tenue pour
                                        responsable du fonctionnement, du contenu ou de tout élément présent ou
                                        obtenu par l’intermédiaire de ces sites.
                                    </p>
                                    <p>
                                        9.4. La mise en place de tels liens ou la référence à toutes informations,
                                        articles ou services fournis par une tierce personne, ne peut et ne droit
                                        pas être interprétée comme un aval exprès ou tacite, par la
                                        Micro-entreprise, de ces sites et de ces éléments ni de leurs contenus.
                                    </p>
                                    <p>
                                        9.5. La Micro-entreprise n’est pas responsable de la disponibilité de ces
                                        sites et ne peut en contrôler le contenu ni valider la publicité, le ou les
                                        service(s) et autres informations diffusées sur ces sites internet.
                                    </p>
                                    <p>
                                        9.6. Il est expressément stipulé que la Micro-entreprise ne pourra en aucun
                                        cas être tenue responsable, de quelque manière que ce soit, pour le cas où
                                        le matériel informatique ou la messagerie électronique des Clients
                                        rejetterait, par exemple du fait d’un anti-spam, les courriers
                                        électroniques adressés par la Micro-entreprise, et notamment, sans que
                                        cette liste ne soit exhaustive, la copie du ticket de paiement, l’état
                                        récapitulatif de la Commande ou encore le courrier électronique de suivi
                                        d’expédition.
                                    </p>
                                    <p>
                                        9.7. Le Client a pleinement conscience des dispositions du présent article
                                        et notamment des garanties et limitations de responsabilité susvisées,
                                        conditions essentielles sans lesquelles la Micro-entreprise n’aurait jamais
                                        contracté.
                                    </p>
                                    <p>
                                        <strong>10. </strong>
                                        <strong>SÉCURITÉ<u></u></strong>
                                    </p>
                                    <p>
                                        <strong> </strong>
                                    </p>
                                    <p>
                                        Le Client s’engage à ne pas porter atteinte à la sécurité du Site. A cette
                                        fin, il s’engage à ne pas procéder à tout accès et/ou maintien frauduleux
                                        dans le système d’information de la Micro-entreprise. Le Client ne peut non
                                        plus porter atteinte ou entraver le système d’information de la
                                        Micro-entreprise. A défaut, la Micro-entreprise pourra prendre à son
                                        encontre toute mesure et notamment engager sa responsabilité pénale au
                                        titre des articles 323-1 et suivants du Code pénal.<strong></strong>
                                    </p>
                                    <p>
                                        <strong>11. </strong>
                                        <strong>PROPRIÉTÉ INTELLECTUELLE ET DONNEES PERSONELLES</strong>
                                    </p>
                                    <p>
                                        <strong><u> </u></strong>
                                    </p>
                                    <p>
                                        11.1. L’ensemble des éléments de ce Site et le Site lui-même, sont protégés
                                        par le droit d’auteur, le droit des marques, des dessins et modèles et/ou
                                        tous autres droits de propriété intellectuelle. Ces éléments sont la
                                        propriété exclusive de la Micro-entreprise. L’ensemble de ces droits est
                                        réservé pour le monde entier.<strong><u></u></strong>
                                    </p>
                                    <p>
                                        11.2. Le nom et la marque les logos, les dessins et modèles, lettres
                                        stylisées, marques figuratives, et tous les signes représentés sur ce Site
                                        sont et demeureront la propriété exclusive de la Micro-entreprise.
                                    </p>
                                    <p>
                                        11.3. Aucun titre ni droit quelconque sur aucun élément ou logiciel ne sera
                                        obtenu par téléchargement ou copie d’éléments de ce Site. Il est
                                        formellement interdit au Client de reproduire (à part pour son utilisation
                                        personnelle et non commerciale), publier, éditer, transmettre, distribuer,
                                        montrer, enlever, supprimer, ajouter à ce Site et aux éléments et logiciels
                                        qu’il contient, pas plus que les modifier ou effectuer un quelconque
                                        travail en les prenant pour base, ni vendre ou participer à aucune vente en
                                        rapport avec ce Site, les éléments de ce Site ni aucun logiciel y afférant.
                                    </p>
                                    <p>
                                        11.4. La Micro-entreprise concède au Client une licence non exclusive pour
                                        utiliser le Site. Cette licence est strictement personnelle et ne peut en
                                        aucun cas être cédée ou transférée à quel que tiers que ce soit. La licence
                                        est concédée pour la durée d’utilisation du Site.
                                    </p>
                                    <p>
                                        11.5. Toute utilisation par le Client des dénominations sociales, marques
                                        et signes distincts appartenant à la Micro-entreprise est strictement
                                        prohibée sauf en cas d’accord exprès et préalable de la Micro-entreprise.
                                    </p>
                                    <p>
                                        11.6. La Micro-entreprise comprend que la protection des données et de la
                                        vie privée est un enjeu pour l’ensemble des internautes visitant le Site.
                                        La Micro-entreprise s’engage, conformément à la réglementation RGPD, à
                                        respecter votre vie privée et à protéger vos données à caractère personnel,
                                        c’est à dire susceptible de vous identifier directement ou indirectement en
                                        tant que personne.
                                    </p>
                                    <p>
                                        11.7. Dans le cadre de la commande, La Micro-entreprise a vocation à
                                        récolter des données personnelles du Client. La Micro-entreprise s’engage à
                                        protéger les données à caractère personnel des clients.
                                    </p>
                                    <p>
                                        11.8. Les fichiers comportant des données à caractère personnel nécessaires
                                        pour la commande sont notamment conservés sur les serveurs de l’hébergeur
                                        du Site. Ce prestataire assure être en conformité avec les prescriptions du
                                        règlement général sur la protection des données (RGPD). La Micro-entreprise
                                        ne communique pas et ne fait pas commerce des données personnelles des
                                        clients.
                                    </p>
                                    <p>
                                        11.9. Au stade de la commande sur le Site, le Client consent expressément à
                                        la collecte et au traitement de ses données à caractère personnel
                                        nécessaires pour effectuer les commandes.
                                    </p>
                                    <p>
                                        11.10. Les données personnelles récoltées par la Micro-entreprise ont pour
                                        objet de permettre la réalisation de la commande. Les différentes données à
                                        caractère personnelles ne seront pas conservées plus longtemps que
                                        nécessaire aux fins pour lesquelles elles ont été récoltées, y compris au
                                        regard du respect des obligations légales ou fiscales.
                                    </p>
                                    <p>
                                        11.11. Conformément aux dispositions de la loi n° 78-17 du 6 janvier 1978,
                                        telle que modifiée par la loi n°2004-801 du 6 août 2004 dite ’’Informatique
                                        et Libertés’’, et au règlement général sur la protection des données
                                        (RGPD), sous réserve de justifier de votre identité, tout Client, quelle
                                        que soit sa nationalité, dispose d’un droit d’accès, de modification et de
                                        suppression de ses données à caractère personnel. Chaque Client est
                                        également en droit de solliciter une limitation du traitement de ses
                                        données et dispose, par ailleurs, d’un droit à la portabilité des données
                                        ainsi que d’un droit d’opposition au traitement des données à caractère
                                        personnel le concernant.
                                    </p>
                                    <p>
                                        11.12. Aux fins d’application de la présente clause et, notamment, de
                                        s’assurer du traitement de confidentialité des données des Clients, la
                                        Micro-entreprise a désigné, conformément aux dispositions du règlement
                                        général sur la protection des données (RGPD), un délégué à la protection
                                        des données, qu’il est possible de contacter à l’adresse suivante :
                                        dev.eMenila@gmail.com
                                    </p>
                                    <p>
                                        11.13. En tout état de cause, tout Client a le droit de faire toute
                                        réclamation auprès de la CNIL.
                                    </p>
                                    <p>
                                        <strong>12. </strong>
                                        <strong>NEWSLETTER</strong>
                                    </p>
                                    <p>
                                        12.1. En cochant la case prévue à cet effet ou en donnant expressément son
                                        accord à cette fin, le Client accepte que la Micro-entreprise puisse lui
                                        faire parvenir, à une fréquence et sous une forme déterminée par elle, une
                                        newsletter (lettre d’information) pouvant contenir des informations
                                        relatives à son activité.
                                    </p>
                                    <p>
                                        12.2. Lorsque le Client coche la case prévue à cet effet dans le processus
                                        d’inscription sur le Site pour passer la Commande, il accepte de recevoir
                                        des offres commerciales de la Micro-entreprise pour des Servicesanalogues à
                                        ceux commandés.
                                    </p>
                                    <p>
                                        12.3. Les Clients auront la faculté de se désinscrire de la newsletter en
                                        cliquant sur le lien prévu à cet effet, présent dans chacune des
                                        newsletters (lettres d’information).
                                    </p>
                                    <p>
                                        <strong>13. </strong>
                                        <strong>LISTE D’OPPOSITION AU DEMARCHAGE TELEPHONIQUE</strong>
                                    </p>
                                    <p>
                                        Le Client a la possibilité de s’inscrire gratuitement sur une liste
                                        d’opposition au démarchage téléphonique BLOCTEL (www.bloctel.gouv.fr) afin
                                        de ne plus être démarché téléphoniquement par un professionnel avec lequel
                                        il n’a pas de relation contractuelle en cours, conformément à la loi
                                        n°2014-344 du 17 mars 2014 relative à la consommation.
                                    </p>
                                    <p>
                                        Tout consommateur a la possibilité de s’inscrire gratuitement sur cette
                                        liste sur le site
                                        <a href="https://conso.bloctel.fr/index.php/inscription.php">
                                            https://conso.bloctel.fr/index.php/inscription.php
                                        </a>
                                        .
                                    </p>
                                    <p>
                                        <strong>14. </strong>
                                        <strong>LOI APPLICABLE ET ATTRIBUTION DE JURIDICTION</strong>
                                    </p>
                                    <p>
                                        14.1. Les présentes CGV sont régies et interprétées conformément au droit
                                        français, sans tenir compte des principes de conflits de lois.
                                    </p>
                                    <p>
                                        14.2. En cas de litige susceptible de survenir à l’occasion de
                                        l’interprétation et/ou de l’exécution des présentes ou en relation avec les
                                        présentes CGV, le Client peut décider de soumettre le litige avec la
                                        Micro-entreprise à une procédure de médiation conventionnelle ou tout autre
                                        mode alternatif de règlement des différends.
                                    </p>
                                    <p>
                                        14.3. Conformément aux dispositions du Code de la consommation concernant
                                        le règlement amiable des litiges, la Micro-entreprise adhère au Service du
                                        Médiateur du e-commerce medicys dont les coordonnées sont les suivantes :
                                        33000 BORDEAUX – https://medicys-conventionnel.fr/
                                    </p>
                                    <p>
                                        Vous pouvez recourir au service de médiation pour les litiges de
                                        consommation liés à une commande effectuée sur internet.
                                    </p>
                                    <p>
                                        Pour connaître les modalités de saisine du Médiateur :
                                        https://medicys-conventionnel.fr/
                                    </p>
                                    <p>
                                        14.4. Il est enfin rappelé que la médiation n'est pas obligatoire mais
                                        uniquement proposée afin de résoudre les litiges en évitant un recours à la
                                        justice
                                    </p>
                                    <p>
                                        14.5. Le Client peut également se rendre sur la plateforme européenne de
                                        règlement des litiges de consommation mise en place par la Commission
                                        européenne à l’adresse suivante et répertoriant l’ensemble des organismes
                                        de règlement de litiges agrées en France :
                                        <a href="https://webgate.ec.europa.eu/odr/">
                                            https://webgate.ec.europa.eu/odr/
                                        </a>
                                        .
                                    </p>
                                    <p>
                                        14.6. En cas d’échec de cette procédure de médiation ou si le Client
                                        souhaite saisir une juridiction, les règles du code de procédure civile
                                        s’appliqueront.
                                    </p>
                                    <br clear="all"/>
                                    <p>
                                        <strong> </strong>
                                    </p>
                                    <div>
                                        <p align="center">
                                            <strong>Annexe 1</strong>
                                        </p>
                                        <p align="center">
                                            <strong>FORMULAIRE DE RETRACTATION</strong>
                                        </p>
                                        <p>
                                            Radouane El Moukhtari108 Cours Saint-Louis, 33300 Bordeaux, France
                                        </p>
                                        <p>
                                            dev.eMenila@gmail.com
                                        </p>
                                        <p>
                                            07 60 96 39 12
                                        </p>
                                        <p>
                                            Je vous notifie par la présente, ma rétractation du contrat portant sur
                                            la vente du ou des produit(s)/service(s) ci-après mentionné(s) :
                                        </p>
                                        <p>
                                            Commandé le :
                                        </p>
                                        <p>
                                            Reçu le :
                                        </p>
                                        <p>
                                            Numéro de commande :
                                        </p>
                                        <p>
                                            Nom du client :
                                        </p>
                                        <p>
                                            Adresse du client :
                                        </p>
                                        <p>
                                            Date :
                                        </p>
                                        <p>
                                            Signature du client :
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default LegalNotice;
